<template>
  <v-container fluid>
    <v-card>
      <v-card-title>{{ form.name }}</v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-select
                v-model="form.companies"
                :items="form.companies"
                item-text="name"
                item-value="id"
                label="Uzņēmumi"
                attach
                multiple
                outlined
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-select
                v-model="form.advertisements"
                :items="form.advertisements"
                item-text="name"
                item-value="id"
                label="Reklāmas"
                attach
                multiple
                outlined
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-select
                v-model="form.users"
                :items="form.users"
                item-text="name"
                item-value="id"
                label="Menedžers"
                attach
                multiple
                outlined
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field label="Nosaukums" v-model="form.name" outlined readonly/>
            </v-col>
            <v-col cols="12" md="4">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="form.date_start"
                persistent
                width="290px"
                disabled
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_start"
                    label="Sākuma datums"
                    prepend-icon="fa-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date_start" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(form.date_start)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="4">
              <v-dialog
                ref="dialog2"
                v-model="modal2"
                :return-value.sync="form.date_end"
                persistent
                width="290px"
                disabled
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_end"
                    label="Datuma beigas"
                    prepend-icon="fa-calendar"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.date_end" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog2.save(form.date_end)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="9">
              <v-textarea label="Apraksts" v-model="form.description" outlined readonly/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-color-picker
                v-model="form.color"
                mode="hexa"
                hide-inputs
                dot-size="30"
                readonly
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/axios'

export default {
  name: 'TaskShow',
  data: () => ({
    taskId: null,
    modal: false,
    modal2: false,
    form: {
      user_id: null,
      advertisement_id: null,
      company_id: null,
      name: '',
      description: '',
      date_start: '',
      date_end: '',
      color: '',
      priority: 0,
      status: 1
    },
    advertisements: [],
    companies: [],
    users: []
  }),
  methods: {
    getCompanies () {
      axios.get('companies').then(response => {
        this.companies = response.data.data
      })
    },
    getAdvertisements () {
      axios.get('advertisements').then(response => {
        this.advertisements = response.data.data
      })
    },
    getUsers () {
      axios.get('users').then(response => {
        this.users = response.data
      })
    },
    fetchData () {
      axios.get('tasks/' + this.taskId).then(response => {
        this.form = response.data
      })
    }
  },
  created () {
    this.taskId = this.$route.params.id
    this.getCompanies()
    this.getAdvertisements()
    this.getUsers()
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
